export enum technos {
    NOCODESTACK = -2,
    AUTRE = -1,
    LARAVEL = 1,
    ANGULAR = 2,
    JAVA = 3,
    NODETS = 4,
    GENERIC_FRONT = 5,
    WEWEB = 6,
    DIRECTUS = 7,
    STRAPI = 8,
    N8N = 9,
    WEBCAPSULE = 10,
    KEYCLOAK = 11,
    INFISICAL = 12,
    XANO = 13,
    BLANK = 14,
    XANO_CLOUD = 15,
    SUPABASE = 16,
    KEYCLOAK_2 = 17,
    WEBFLOW = 101,
    POSTGRESQL = 105,
    FLUTTERFLOW = 106,
    LLAMA = 107,
    METABASE = 108,
    PLASMIC = 109,
}

export const technosName = {
    [technos.ANGULAR]: 'angular',
    [technos.WEWEB]: 'weweb',
    [technos.DIRECTUS]: 'directus',
    [technos.SUPABASE]: 'supabase',
    [technos.KEYCLOAK_2]: 'keycloak',
    [technos.N8N]: 'n8n',
    [technos.WEBFLOW]: 'webflow',
    [technos.POSTGRESQL]: 'postgresql',
    [technos.STRAPI]: 'strapi',
    [technos.FLUTTERFLOW]: 'flutterflow',
    [technos.LLAMA]: 'llama',
    [technos.METABASE]: 'metabase',
    [technos.PLASMIC]: 'plasmic',
    [technos.XANO]: 'xano_standalone',
    [technos.XANO_CLOUD]: 'xano_cloud',
    [technos.BLANK]: 'custom',
};
